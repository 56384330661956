<template>
  <div>
    <div class="title">月工资表</div>
	<div class="filter_box">
	</div>

    <el-date-picker v-model="search" type="year"></el-date-picker>

    <div class="payroll_list" v-for="item in 3" :key="item">
      <div class="table_title">2020年6月</div>
      <div class="payroll_item">
        <div class="payroll_item_title">6月份工资汇总表</div>
        <el-divider direction="vertical"></el-divider>
        <div class="payroll_item_count">
          <div>
            <div>计薪人数</div>
            <div>554人</div>
          </div>
          <div>
            <div>新入职</div>
            <div>0人</div>
          </div>
          <div>
            <div>离职</div>
            <div>2人</div>
          </div>
          <div>
            <div>转正</div>
            <div>5人</div>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="payroll_item_pay">
          <div>
            <div>应发工资</div>
            <div>¥396,800.00</div>
          </div>
          <div>
            <div>个税</div>
            <div>¥0.00</div>
          </div>
          <div>
            <div>实发工资</div>
            <div>¥396,800.00</div>
          </div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="payroll_item_detail">查看详情</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: String(new Date().getFullYear()),
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}
.filter_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;

    .el-date-editor {
        height: 36px;
        margin-right: 10px;

        ::v-deep .el-input__inner {
            height: 36px;
        }
    }

    .filter_search {
        display: flex;
        align-items: center;

        .filter_search_input {
            width: 350px;
            height: 36px;
            margin-right: 10px;

            ::v-deep .el-input__inner {
                height: 36px;
            }
        }
    }

    .filter_btns {
        display: flex;
        align-items: center;

        > div:not(:first-child) {
            margin-left: 20px;
        }
    }
}
.payroll_list {
  
  .payroll_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #E1E6EB;
    height: 100px;

    .payroll_item_title {
      flex: 3;
      text-align: center;
      font-size: 16px;
      color: #1C2438;
    }

    .payroll_item_count,
    .payroll_item_pay {
      flex: 9;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      >div{
        >div:nth-child(1){
          color: #80848F;
          font-size: 14px;
        }
        >div:nth-child(2){
          color: #495060;
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }

    .payroll_item_detail{
      flex: 2;
      color: #4db9d5;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
